import { useMutation } from '@tanstack/react-query';

const startReportExport = async ({ unitId, reportId, versionNumber, bookmarkState, exportFormat }) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unit/${unitId}/powerBIReport/${reportId}/version/${versionNumber}/startExport`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ bookmarkState, exportFormat })
        }
    );
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();

    return data;
};

const useStartPowerBIReportExport = (params) => {
    return useMutation(startReportExport, params);
};

export default useStartPowerBIReportExport;
