/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { MoreVert, RestoreRounded, SaveRounded } from '@material-ui/icons';

import { useUpdatePowerBIReportVersionEmbedConfigForUnit } from 'hooks/unit';

const PowerBIReportSlicerActions = ({ reportRef, unitId, reportId, versionNumber, hasSavedSlicers }) => {
    const { t } = useTranslation();

    const { mutateAsync: updatePowerBIReportVersionEmbedConfigForUnit } =
        useUpdatePowerBIReportVersionEmbedConfigForUnit();

    return (
        <Box sx={{ display: 'flex', ml: '8px' }}>
            <Tooltip title={t('Save selection')}>
                <IconButton
                    size='small'
                    onClick={async () => {
                        const pages = await reportRef.getPages();

                        const mergedVisuals = await Promise.all(pages.map((p) => p.getVisuals()));
                        const flattenedVisuals = mergedVisuals.flat();

                        const slicerVisuals = flattenedVisuals.filter((v) => v.type === 'slicer');

                        const slicerData = await Promise.all(
                            slicerVisuals.map((v) => v.getSlicerState().catch(() => {}))
                        );

                        const nonEmptySlicerData = slicerData.filter((slicer) => {
                            if (!slicer) return false;

                            return slicer.filters.length > 0;
                        });

                        const embedSlicers = nonEmptySlicerData.map((slicer) => {
                            return {
                                selector: {
                                    $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
                                    target: slicer.filters[0].target
                                },
                                state: { filters: slicer.filters }
                            };
                        });

                        const reportVersionConfigPayload = {
                            reportVersionConfig: {
                                slicers: embedSlicers
                            }
                        };

                        await updatePowerBIReportVersionEmbedConfigForUnit({
                            unitId,
                            reportId,
                            versionNumber,
                            reportVersionConfig: reportVersionConfigPayload
                        });
                    }}
                >
                    <SaveRounded />
                </IconButton>
            </Tooltip>

            {hasSavedSlicers && (
                <Tooltip title={t('Reset selection')}>
                    <IconButton
                        style={{ marginLeft: '8px' }}
                        size='small'
                        onClick={async () => {
                            await updatePowerBIReportVersionEmbedConfigForUnit({
                                unitId,
                                reportId,
                                versionNumber,
                                reportVersionConfig: { reportVersionConfig: {} }
                            });

                            window.location.reload();
                        }}
                    >
                        <RestoreRounded />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};

export default PowerBIReportSlicerActions;
