/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useDownloadPowerBIReportExport } from 'hooks/unit';

const DownloadExportTrigger = ({ exportId, reportId, unitId, versionNumber }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [isDownloaded, setIsDownloaded] = useState(false);

    const { mutateAsync: downloadExport } = useDownloadPowerBIReportExport({
        onSuccess: () => {
            setIsDownloaded(true);
        },
        onError: () => {
            setIsDownloaded(false);
            enqueueSnackbar(t('Server error'), { variant: 'error' });
        }
    });

    useEffect(() => {
        if (!isDownloaded) downloadExport({ unitId, reportId, versionNumber, exportId });
    }, [downloadExport, exportId, isDownloaded, reportId, unitId, versionNumber]);

    return null;
};

export default DownloadExportTrigger;
