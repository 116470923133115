/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { CircularProgress, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { GetAppRounded } from '@material-ui/icons';

import { logError } from 'helpers/error';
import { useGetPowerBIReportExportStatus } from 'hooks/unit';
import useStartPowerBIReportExport from 'hooks/unit/mutations/useStartPowerBIReportExportForUnit';

import DownloadExportTrigger from './DownloadExportTrigger';

const PowerBIExportReport = ({ reportRef, unitId, reportId, versionNumber }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [exportId, setExportId] = useState(null);

    const { data: exportStatusData } = useGetPowerBIReportExportStatus({
        reportId,
        unitId,
        exportId,
        versionNumber
    });

    const { mutateAsync: startExport } = useStartPowerBIReportExport({
        onSuccess: (data) => {
            if (!data) return;
            const { exportId } = data;
            if (!exportId) return;

            setExportId(exportId);
        }
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExport = async (format) => {
        handleClose();

        const bookmarksManager = reportRef.bookmarksManager;
        const capturedBookmark = await bookmarksManager.capture().catch((error) => {
            enqueueSnackbar(t('Server error'), { variant: 'error' });
            logError(error);
        });

        if (!capturedBookmark) return;

        startExport({
            unitId,
            reportId,
            versionNumber,
            bookmarkState: capturedBookmark.state,
            exportFormat: format
        });
    };

    return (
        <>
            {exportStatusData?.exportStatus === 'Running' && <CircularProgress size={20} />}

            {exportStatusData?.exportStatus !== 'Running' && (
                <>
                    <Tooltip title={t('Export')} /* TODO: ADD TRANSLATION */>
                        <IconButton onClick={handleClick} size='small'>
                            <GetAppRounded />
                        </IconButton>
                    </Tooltip>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                        <MenuItem onClick={() => handleExport('PDF')}>PDF</MenuItem>
                        <MenuItem onClick={() => handleExport('PPTX')}>PPTX</MenuItem>
                        <MenuItem onClick={() => handleExport('PNG')}>PNG</MenuItem>
                    </Menu>
                </>
            )}

            {exportStatusData?.exportStatus === 'Succeeded' && (
                <DownloadExportTrigger
                    key={exportId}
                    unitId={unitId}
                    reportId={reportId}
                    versionNumber={versionNumber}
                    exportId={exportId}
                />
            )}
        </>
    );
};

export default PowerBIExportReport;
