import React, { useEffect, useState } from 'react';
import Favicon from 'react-favicon';
import reactManifest from 'react-manifest';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    AppBar,
    Avatar,
    Badge,
    Container,
    IconButton,
    makeStyles,
    Toolbar,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { MenuRounded } from '@material-ui/icons';

import { ActiveUnitButton } from 'common/components';
import { unitModel } from 'common/models';
import { UnitSelectorDialog } from 'common/modules';

export const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    appBar: {
        boxShadow: 'none',
        backgroundColor: '#f4f6f8',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        height: 56
    },
    brandLogo: {
        minWidth: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        minHeight: 56,
        paddingLeft: 0,
        color: '#040e13'
    },
    logo: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.divider}`
    },
    menuButton: {
        borderRadius: 'unset',
        width: 56,
        color: theme.palette.icon
    },
    badgeAnchor: {
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.divider}`,
        transform: 'scale(1) translate(25%, 10%)',
        padding: 0
    },
    badgeIcon: {
        width: 14,
        height: 14
    }
}));

export const Topbar = ({ activeUnit, units, handleSidebarOpen }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.between('sm', 'xl'), { defaultMatches: true });

    const [openUnitSelector, setOpenUnitSelector] = useState(false);
    const [logo, setLogo] = React.useState('');

    useEffect(() => {
        if (activeUnit) {
            document.title = activeUnit?.name;

            let logo = null;

            if (activeUnit.logo) {
                logo = activeUnit.logo;
            } else {
                let unitId = activeUnit.parentId;

                while (unitId !== null) {
                    const unit = units.find((u) => u.id === unitId);

                    if (unit) {
                        if (unit.logo && unit.logo.length) {
                            logo = unit.logo;
                            break;
                        }

                        unitId = unit.parentId;
                    } else {
                        break;
                    }
                }
            }

            const icon = {
                sizes: '64x64 48x48 32x32 24x24 16x16',
                src: logo
            };

            switch (true) {
                //
                case /image\/svg/.test(logo):
                    icon.type = 'image/svg+xml';
                    icon.sizes = 'any';
                    icon.src = logo;
                    break;

                case /image\/jpg/.test(logo):
                case /image\/jpeg/.test(logo):
                    icon.type = 'image/jpg';
                    break;

                case /image\/png/.test(logo):
                    icon.type = 'image/png';
                    break;

                case /image\/webp/.test(logo):
                    icon.type = 'image/webp';
                    break;

                default:
                    icon.src = 'favicon.ico';
                    icon.type = 'image/x-icon';

                    break;
            }

            const manifestDetails = {
                short_name: activeUnit.name,
                name: activeUnit.name,
                icons: [icon]
            };

            reactManifest.update(manifestDetails, '#manifest-placeholder');

            setLogo(logo);
        }
    }, [activeUnit, units]);

    return (
        <AppBar className={clsx(classes.appBar)}>
            <Favicon url={logo} />
            <Toolbar className={classes.header}>
                {!isDesktop && (
                    <IconButton onClick={handleSidebarOpen} size='small' className={classes.menuButton}>
                        <Badge
                            badgeContent={<MenuRounded className={classes.badgeIcon} />}
                            classes={{
                                anchorOriginBottomRightRectangle: classes.badgeAnchor
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <Avatar className={classes.logo}>
                                <img src={logo} alt='logo' width='40' height='40' />
                            </Avatar>
                        </Badge>
                    </IconButton>
                )}

                {isDesktop && (
                    <div className={classes.brandLogo}>
                        <Avatar className={classes.logo}>
                            <img src={logo} alt='logo' width='40' height='40' />
                        </Avatar>
                    </div>
                )}

                <Container style={{ maxWidth: 1536 }} /*  maxWidth='xl' */>
                    <ActiveUnitButton activeUnit={activeUnit} onClick={() => setOpenUnitSelector(!openUnitSelector)} />
                    <UnitSelectorDialog dialogOpen={openUnitSelector} setDialogOpen={setOpenUnitSelector} />
                </Container>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    activeUnit: PropTypes.shape(unitModel),
    units: PropTypes.array,
    handleSidebarOpen: PropTypes.func
};
