import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Chip, styled } from '@material-ui/core';
import { ArrowDropDownRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { unitModel } from 'common/models';

const StyledButton = styled(Button)(({ theme }) => ({
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    borderRadius: 20
}));

const useStyles = makeStyles(() => ({
    unitSelectorLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}));

export const ActiveUnitButton = ({ onClick, activeUnit }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <StyledButton
                color='inherit'
                onClick={onClick}
                variant='outlined'
                endIcon={<ArrowDropDownRounded style={{ height: '24px', width: '24px' }} />}
            >
                <span className={classes.unitSelectorLabel}>
                    {(activeUnit && activeUnit.name) || t('No units available')}
                </span>
            </StyledButton>
            {activeUnit?.isDemo && <Chip label='Demo' color='secondary' size='small' />}
        </>
    );
};

ActiveUnitButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    activeUnit: PropTypes.shape(unitModel)
};
