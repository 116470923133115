import React, { forwardRef, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Select,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { AllInboxRounded } from '@material-ui/icons';

import { ActiveUnitButton } from 'common/components';
import { UnitSelectorDialog } from 'common/modules';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';

import PowerBIExportReport from './PowerBIExportReport';
import PowerBIReportSlicerActions from './PowerBIReportSlicerActions';

const PowerBIReportHeader = forwardRef(
    ({ versionKeys = [], currentVersionKey, hasSavedSlicers, unitId, reportId, onChangeVersion }, reportRef) => {
        const { t } = useTranslation();
        const {
            state: { activeUnit }
        } = useContext(activeUnitContext);

        const theme = useTheme();

        const isDesktop = useMediaQuery(theme.breakpoints.between('sm', 'xl'), { defaultMatches: true });

        const [openUnitSelector, setOpenUnitSelector] = useState(false);
        const [anchorEl, setAnchorEl] = useState(null);

        return (
            <Box sx={{ width: '100%', pt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: '10px' }}>
                    <Box>
                        {isDesktop && (
                            <>
                                <ActiveUnitButton
                                    activeUnit={activeUnit}
                                    onClick={() => setOpenUnitSelector(!openUnitSelector)}
                                />
                                <UnitSelectorDialog dialogOpen={openUnitSelector} setDialogOpen={setOpenUnitSelector} />
                            </>
                        )}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {versionKeys?.length > 1 && (
                            <>
                                <Tooltip title={t('Version')}>
                                    <IconButton
                                        size='small'
                                        onClick={(e) => setAnchorEl(e.currentTarget)}
                                        style={{ marginRight: '8px', display: 'none' }}
                                    >
                                        <AllInboxRounded />
                                    </IconButton>
                                </Tooltip>
                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                                    {versionKeys.map((versionKey) => (
                                        <MenuItem
                                            key={versionKey}
                                            onClick={() => {
                                                onChangeVersion(versionKey);
                                                setAnchorEl(null);
                                            }}
                                        >
                                            {versionKey}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        )}

                        {reportRef?.current && (
                            <PowerBIExportReport
                                reportRef={reportRef.current}
                                unitId={unitId}
                                reportId={reportId}
                                versionNumber={currentVersionKey}
                            />
                        )}

                        {reportRef?.current && (
                            <PowerBIReportSlicerActions
                                reportRef={reportRef.current}
                                unitId={unitId}
                                reportId={reportId}
                                versionNumber={currentVersionKey}
                                hasSavedSlicers={hasSavedSlicers}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        );
    }
);

PowerBIReportHeader.displayName = 'PowerBIReportHeader';

PowerBIReportHeader.propTypes = {
    versionKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
    currentVersionKey: PropTypes.number.isRequired,
    hasSavedSlicers: PropTypes.bool.isRequired,
    unitId: PropTypes.string.isRequired,
    reportId: PropTypes.string.isRequired,
    onChangeVersion: PropTypes.func.isRequired
};

export default PowerBIReportHeader;
