import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';

import { useGetPowerBIReportById } from 'hooks/powerBIReport';

import { PowerBIReportContent } from './PowerBIReportContent';

import './report.css';

export const PowerBIReport = (props) => {
    const { reportId, unitId } = props.match.params;

    const { data } = useGetPowerBIReportById({ reportId });

    const report = data?.report;

    const versions = report?.versions;
    const versionKeys = versions ? Object.keys(versions).map(Number) : [];

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('Retrieving and analyzing data...');

    useEffect(() => {
        setIsLoading(true);
        setLoadingText('Retrieving and analyzing data...');
    }, [reportId]);

    return (
        <Box sx={{ width: '100%', textAlign: 'center' }} key={reportId + unitId}>
            {isLoading && (
                <Box component={Backdrop} sx={{ color: 'white', marginTop: 48 }} open>
                    <Box>
                        <CircularProgress color='primary' size='64px' />

                        <Typography variant='h5' color='inherit' style={{ marginTop: 16 }}>
                            {t(loadingText)}
                        </Typography>
                    </Box>
                </Box>
            )}

            {report && versionKeys.length ? (
                <PowerBIReportContent
                    isLoading={isLoading}
                    unitId={unitId}
                    report={report}
                    versionKeys={versionKeys}
                    setIsLoading={setIsLoading}
                    setLoadingText={setLoadingText}
                />
            ) : null}
        </Box>
    );
};

PowerBIReport.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            reportId: PropTypes.string,
            unitId: PropTypes.string
        })
    })
};
