import { useQuery } from '@tanstack/react-query';

const getExportStatus = async ({ reportId, versionNumber, unitId, exportId }) => {
    const url = new URL(
        `${process.env.REACT_APP_API_URL}/unit/${unitId}/powerBIReport/${reportId}/version/${versionNumber}/checkExport/${exportId}`
    );

    const response = await fetch(url.toString(), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();

    return data;
};

const useGetPowerBIReportExportStatus = (params) => {
    return useQuery(['unit/powerBIReport/version/exportStatus', params], () => getExportStatus(params), {
        enabled: !!params.exportId,
        refetchInterval: (data) => {
            if (!data) return undefined;

            if (data.exportStatus === 'Succeeded') return undefined;

            return 5000;
        }
    });
};

export default useGetPowerBIReportExportStatus;
