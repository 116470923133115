import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { Add, Clear } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

function AddReport({ addReports, reportCategories, existingReports }) {
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedReports, setSelectedReports] = useState([]);

    const reportCategoriesWithUnselectedReports = Object.entries(reportCategories).filter((category) =>
        category[1].some((report) => !existingReports.find((existingReport) => existingReport === report.id))
    );

    return (
        <>
            <Button
                variant='contained'
                size='small'
                color='primary'
                startIcon={<Add />}
                onClick={() => setIsOpen(true)}
            >
                {t('Add report')}
            </Button>

            {isOpen && (
                <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth maxWidth='sm'>
                    <DialogTitle>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Typography variant='h6' color='primary'>
                                {t('Add report')}
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    setIsOpen(false);
                                    setSelectedReports([]);
                                }}
                            >
                                <Clear />
                            </IconButton>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            multiple
                            id='reports-autocomplete'
                            options={reportCategoriesWithUnselectedReports.flatMap((category) =>
                                category[1]
                                    .filter(
                                        (report) =>
                                            !existingReports.find((existingReport) => existingReport === report.id)
                                    )
                                    .map((rep) => ({ ...rep, grouping: category[0] }))
                            )}
                            getOptionLabel={(option) => option.name[i18n.language] || option.name['en-US']}
                            disableCloseOnSelect
                            value={selectedReports}
                            renderTags={() => {}}
                            groupBy={(option) => option.grouping}
                            renderOption={(option, { selected }) => (
                                <Tooltip
                                    title={option?.internalNote ? `Note: ${option.internalNote}` : ''}
                                    arrow
                                    placement='left'
                                >
                                    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                        <Checkbox checked={selected} />
                                        <ListItemText primary={option.name[i18n.language] || option.name['en-US']} />
                                    </Box>
                                </Tooltip>
                            )}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField {...params} variant='outlined' label='Reports' placeholder='Reports' />
                            )}
                            onChange={(_, newValue) => {
                                const newValueWithoutGrouping = newValue.map(({ grouping, ...rest }) => rest);
                                setSelectedReports(newValueWithoutGrouping);
                            }}
                        />

                        <List>
                            {selectedReports.map((report) => (
                                <ListItem key={report.id}>
                                    <ListItemText primary={`+ ${report.name[i18n.language] || report.name['en-US']}`} />
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>
                    <DialogActions style={{ padding: '0 16px 16px' }}>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                addReports(selectedReports);
                                setSelectedReports([]);
                                setIsOpen(false);
                            }}
                        >
                            {t('Add')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

AddReport.propTypes = {
    existingReports: PropTypes.array,
    reportCategories: PropTypes.object,
    addReports: PropTypes.func
};

export default AddReport;
