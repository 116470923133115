import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Divider, List } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { ArrowForwardIosRounded, CloseRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
    active: {
        color: theme.palette.sidebar.active
    },
    arrowButton: {
        borderRadius: 4,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 11,
        paddingBottom: 11
    }
}));

function SidebarNav({ pages, className, open, handleSidebarOpen, onClose, ...rest }) {
    const classes = useStyles();
    const loc = useLocation();

    return (
        <>
            <div>
                {open ? (
                    <IconButton className={classes.arrowButton} onClick={onClose} variant='contained'>
                        <CloseRounded />
                    </IconButton>
                ) : (
                    <IconButton className={classes.arrowButton} onClick={handleSidebarOpen} variant='contained'>
                        <ArrowForwardIosRounded />
                    </IconButton>
                )}
            </div>
            <Divider />
            <List {...rest} disablePadding dense className={className}>
                {pages.map((page, i) => (
                    <NavItem
                        key={page + i}
                        page={page}
                        handleSidebarOpen={handleSidebarOpen}
                        isOpenSidebar={open}
                        currentPath={loc.pathname}
                    />
                ))}
            </List>
        </>
    );
}

SidebarNav.defaultProps = {
    pages: []
};

SidebarNav.propTypes = {
    pages: PropTypes.array,
    className: PropTypes.string,
    open: PropTypes.bool,
    handleSidebarOpen: PropTypes.func,
    onClick: PropTypes.func,
    onClose: PropTypes.func
};

export default SidebarNav;
