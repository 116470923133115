import gql from 'graphql-tag';

/**
 *
 */

export const FRAGMENT_PBI_CATEGORY_REPORTS = gql`
    fragment pbiCategoryReport on CategoryReport {
        id
        name
        description
        isReusable
        sequence
        pbiReportId
        pbiWorkspaceId
    }
`;
/**
 *
 */

export const FRAGMENT_PBI_REPORTS_CATEGORY = gql`
    fragment pbiCategory on ReportCategory {
        id
        name
        sequence
        isActive
        parentId
        reports {
            ...pbiCategoryReport
        }
    }
    ${FRAGMENT_PBI_CATEGORY_REPORTS}
`;
