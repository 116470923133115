import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';

import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { SAVE_ACTIVE_UNIT, UPDATE_UNIT } from 'graphql/mutations/units';
import { GET_REPORT_INDUSTRIES_LIST } from 'graphql/queries/report';
import { GET_UNIT_DETAILS } from 'graphql/queries/unit';
import { logError } from 'helpers/error';

const UnitContext = createContext({});

const UnitProvider = ({ children }) => {
    const {
        state: { activeUnit, units, mePermissions },
        setActiveUnit,
        setAllUnits,
        setMePermissions
    } = useContext(activeUnitContext);

    const queryClient = useQueryClient();

    const [isLoading] = useState(false);
    const [industries, setIndustries] = useState([]);
    const [editingUnit, setEditingUnit] = useState(activeUnit);
    const [isEdited, setIsEdited] = useState(false);
    const [saveActiveUnit] = useMutation(SAVE_ACTIVE_UNIT);
    const [categories, setCategories] = useState(industries.flatMap((industry) => industry.categories) || []);
    const { enqueueSnackbar } = useSnackbar();
    const isMounted = useRef(false);

    const resetEditingUnit = () => {
        setEditingUnit({
            ...activeUnit
        });
    };

    const createNewUnit = () => {
        setEditingUnit({
            name: '',
            customerId: '',
            orgNr: '',
            email: '',
            phone: '',
            location: {},
            parentId: editingUnit?.id,
            externalReports: [],
            powerBIReportConfigs: {},
            reports: [],
            users: [],
            tags: [],
            dataSources: {},
            isDemo: editingUnit?.isDemo,
            isDeleted: false
        });
    };

    const saveUnit = () => {
        const {
            id,
            reports,
            tags,
            name,
            customerId,
            orgNr,
            email,
            phone,
            location,
            parentId,
            isDemo,
            powerBIReportConfigs,
            externalReports,
            dataSources,
            users,
            logo,
            isDeleted
        } = editingUnit;

        setIsEdited(false);
        if (name && name.length) {
            updateUnit({
                variables: {
                    unitId: id,
                    unit: {
                        name,
                        customerId,
                        orgNr,
                        email,
                        phone,
                        location,
                        parentId,
                        isDemo,
                        isDeleted,
                        logo,
                        powerBIReportConfigs,
                        externalReports,
                        users: users?.flatMap((user) => user.id) || [],
                        dataSources,
                        reports: reports?.flatMap((report) => report.id) || [],
                        tags: tags?.flatMap((tag) => tag.id) || []
                    }
                }
            });
        }
    };

    const [getAllReportIndustries] = useLazyQuery(GET_REPORT_INDUSTRIES_LIST, {
        variables: { unitId: activeUnit.id },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            isMounted.current = true;
            const industries = data.getAllReportIndustries || [];
            setIndustries(industries);
            setCategories(industries.flatMap((industry) => industry.categories) || []);
        }
    });

    const [getUnitDetails] = useLazyQuery(GET_UNIT_DETAILS, {
        variables: { id: editingUnit?.id },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const unit = data.getUnitDetails;
            if (unit) {
                setActiveUnit({ ...activeUnit, ...unit });
                setEditingUnit({ ...editingUnit, ...unit });
            }
        }
    });

    const [updateUnit] = useMutation(UPDATE_UNIT, {
        onCompleted: ({ updateUnit }) => {
            if (updateUnit) {
                queryClient.invalidateQueries((query) => query.queryKey[0] === 'powerBIReport/list');
                const isOld = units.find((unit) => unit.id === updateUnit.id);

                if (isOld) {
                    setActiveUnit({ ...activeUnit, ...updateUnit });
                } else {
                    setAllUnits([...units, updateUnit]);
                    const parentPermissions = mePermissions.find(
                        (permission) => permission?.unitId === updateUnit?.parentId
                    );

                    if (parentPermissions) {
                        const { permissions } = parentPermissions;
                        setMePermissions([...mePermissions, { permissions, unitId: updateUnit.id }]);
                    }

                    saveActiveUnit({ variables: { unitId: updateUnit.id } });
                    setActiveUnit({ ...updateUnit });
                    setEditingUnit({ ...updateUnit });
                }
            } else {
                enqueueSnackbar(<span>{'Unit update has Failed!'}</span>, {
                    variant: 'error'
                });

                logError('Unit update has Failed');

                resetEditingUnit();
            }
        }
    });

    useEffect(() => {
        if (!isMounted.current) {
            getAllReportIndustries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isMounted.current && editingUnit?.id) {
            getUnitDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [industries]);

    return (
        <UnitContext.Provider
            value={{
                editingUnit,
                industries,
                isEdited,
                isLoading,
                categories,
                saveUnit,
                setCategories,
                setIsEdited,
                setEditingUnit,
                resetEditingUnit,
                createNewUnit
            }}
        >
            {children}
        </UnitContext.Provider>
    );
};

const useUnitContext = () => useContext(UnitContext);

export { UnitProvider, UnitContext, useUnitContext };

UnitProvider.propTypes = {
    children: PropTypes.element
};
