import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import { Container, LinearProgress, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

import { AuthContext } from 'context/AuthContext';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { ME } from 'graphql/queries/user';

import { CookieAccept, Sidebar, Topbar } from './partials';
import { drawerWidth } from './partials/Topbar/Topbar';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        paddingTop: 56,
        paddingLeft: (props) => (props.isDesktop ? theme.spacing(7) : 0)
    },
    shiftContent: {
        marginLeft: (props) => (props.isDesktop ? drawerWidth - 70 : 0),
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    content: {
        height: '100%',
        minHeight: 'calc(100% - 100px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        paddingLeft: 0,
        paddingRight: 0
    }
}));

const Main = ({ children }) => {
    const { data } = useQuery(ME);
    const history = useHistory();
    const { setUser } = useContext(AuthContext);
    if (data && data.me === null) {
        history.push('/sign-in');
    }

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.between('sm', 'xl'), { defaultMatches: true });
    const classes = useStyles({ isDesktop });
    const [openSidebar, setOpenSidebar] = useState(false);

    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const handleSidebarOpen = () => {
        setOpenSidebar((prev) => !prev);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    useEffect(() => {
        if (data?.me) {
            const { firstName, lastName, email } = data.me;

            setUser({ firstName, lastName, email });
        }
    }, [data, setUser]);

    return (
        <>
            {data && data.me ? (
                <div className={`${classes.root} ${openSidebar && classes.shiftContent}`}>
                    <Topbar units={data.me.units} activeUnit={activeUnit} handleSidebarOpen={handleSidebarOpen} />

                    <Sidebar
                        onClose={handleSidebarClose}
                        open={openSidebar}
                        handleSidebarOpen={handleSidebarOpen}
                        variant={isDesktop ? 'permanent' : 'temporary'}
                        user={data.me}
                        units={data.me.units}
                        activeUnit={activeUnit}
                    />
                    <main className={classes.content}>
                        <Container style={{ maxWidth: 1536 }} /*  maxWidth='xl' */>
                            {(activeUnit && children) || <LinearProgress />}
                        </Container>
                    </main>
                    <CookieAccept />
                </div>
            ) : (
                <LinearProgress />
            )}
        </>
    );
};

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
