import { useMutation } from '@tanstack/react-query';

const downloadReportExport = async ({ unitId, reportId, versionNumber, exportId }) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unit/${unitId}/powerBIReport/${reportId}/version/${versionNumber}/downloadExport/${exportId}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }
    );
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const blob = await response.blob();

    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'export';

    if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match.length === 2) {
            filename = match[1];
        }
    }

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
};

const useDownloadPowerBIReportExport = (params) => {
    return useMutation(downloadReportExport, params);
};

export default useDownloadPowerBIReportExport;
