import React, { useContext, useState } from 'react';

import { Box, useMediaQuery, useTheme } from '@material-ui/core';

import { ActiveUnitButton } from 'common/components';
import { UnitSelectorDialog } from 'common/modules';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';

const ExternalReportHeader = ({}) => {
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.between('sm', 'xl'), { defaultMatches: true });

    const [openUnitSelector, setOpenUnitSelector] = useState(false);

    return (
        <Box sx={{ width: '100%', pt: 1, pb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {isDesktop && (
                    <>
                        <ActiveUnitButton
                            activeUnit={activeUnit}
                            onClick={() => setOpenUnitSelector(!openUnitSelector)}
                        />
                        <UnitSelectorDialog dialogOpen={openUnitSelector} setDialogOpen={setOpenUnitSelector} />
                    </>
                )}
            </Box>
        </Box>
    );
};

ExternalReportHeader.displayName = 'ExternalReportHeader';

export default ExternalReportHeader;
