import gql from 'graphql-tag';

import { FRAGMENT_DATA_SOURCES } from 'graphql/fragments/dataSources';

import { FRAGMENT_PBI_REPORTS_CATEGORY } from '../fragments/pbi.category';
import { FRAGMENT_PBI_REPORTS } from '../fragments/pbi.reports';

export const CREATE_CHILD_UNIT = gql`
    mutation createUnit($parentId: ID, $unitId: String, $name: String, $address: String, $orgNr: String) {
        createUnit(parentId: $parentId, unitId: $unitId, name: $name, address: $address, orgNr: $orgNr) {
            id
            unitId
            name
        }
    }
`;

export const ADD_USERS_TO_UNIT = gql`
    mutation addUsersToUnit($unitId: ID!, $users: [userWithRoles!]!) {
        addUsersToUnit(unitId: $unitId, users: $users) {
            id
            users {
                id
                email
                firstName
                lastName
                phone
                verified
            }
        }
    }
`;

export const ADD_UNITS_TO_USER = gql`
    mutation addUnitsToUser($userId: ID!, $units: [ID!]) {
        addUnitsToUser(userId: $userId, units: $units) {
            id
            firstName
            units {
                id
                name
            }
        }
    }
`;

export const REMOVE_USER_FROM_UNIT = gql`
    mutation removeUserFromUnit($unitId: ID, $userId: ID) {
        removeUserFromUnit(unitId: $unitId, userId: $userId) {
            id
            users {
                id
                email
                firstName
                lastName
                phone
            }
        }
    }
`;

export const REMOVE_USERS_FROM_UNIT = gql`
    mutation removeUsersFromUnit($input: RemoveUsersFromUnitInput) {
        removeUsersFromUnit(input: $input) {
            id
            name
            users {
                id
                firstName
                lastName
                email
                phone
                #userPermissions {
                # unitId
                #Permissions {
                # name
                # permission
                #}
                #}
                units {
                    id
                    name
                    logo
                }
                verified
            }
        }
    }
`;

export const SAVE_ACTIVE_UNIT = gql`
    mutation setActiveUnit($unitId: ID!) {
        setActiveUnit(unitId: $unitId)
    }
`;

export const ADD_FACEBOOK_PAGES_TO_UNITS = gql`
    mutation addFacebookPagesToUnits($input: AddFacebookPagesToUnitsInput) {
        addFacebookPagesToUnits(input: $input)
    }
`;

export const REMOVE_FACEBOOK_PAGES_FROM_UNITS = gql`
    mutation removeFacebookPagesFromUnits($input: RemoveFacebookPagesFromUnitsInput) {
        removeFacebookPagesFromUnits(input: $input)
    }
`;

export const SET_DEFAULT_FACEBOOK_PAGE_ON_UNIT = gql`
    mutation setDefaultFacebookPage($input: SetDefaultFacebookPageInput) {
        setDefaultFacebookPage(input: $input)
    }
`;

export const UPDATE_UNIT = gql`
    mutation updateUnit($unitId: ID, $unit: UnitInput!) {
        updateUnit(unitId: $unitId, unit: $unit) {
            id
            name
            customerId
            orgNr
            email
            parentId
            phone
            isDemo
            isDeleted
            logo
            powerBIReportConfigs
            externalReports {
                name
                url
            }
            location {
                address1
                address2
                postalCode
                city
                county
                country
            }
            invoice {
                address1
                address2
                postalCode
                city
                county
                country
            }
            reports {
                ...pbiReport
            }
            dataSources {
                ...dataSources
            }
            users {
                id
                firstName
                lastName
                email
                phone
                verified
            }
            productFeeds {
                id
                fields {
                    name
                    label
                    visibility
                    fieldType
                }
            }
            productTypes {
                id
                fields {
                    id
                    name
                    label
                    visibility
                    fieldType
                }
            }
            linkExtension {
                _id
                unitId
                extensions {
                    _id
                    name
                    value
                    description
                }
            }
        }
    }

    ${FRAGMENT_DATA_SOURCES}
    ${FRAGMENT_PBI_REPORTS}
    ${FRAGMENT_PBI_REPORTS_CATEGORY}
`;
