import React, { useContext, useEffect } from 'react';
import Favicon from 'react-favicon';
import { useTranslation } from 'react-i18next';
import reactManifest from 'react-manifest';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Avatar, Divider, Drawer } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';

import { unitModel } from 'common/models';
import { AuthContext } from 'context/AuthContext';
import { Context as ActiveUnitContext } from 'context/units/activeUnitContext';
import { usePowerBIReportGetReportsForUnit } from 'hooks/powerBIReport';

import { drawerWidth } from '../Topbar/Topbar';
import { SidebarNav } from './components';
import UserMenu from './components/UserMenu';
import pagesAuthenticated from './permissions/pagesAuthenticated';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            zIndex: `${theme.zIndex.drawer} !important`
        }
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 16,
        overflowX: 'clip'
    },
    nav: {
        paddingTop: 0,
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    drawerOpen: {
        width: drawerWidth,
        backgroundColor: '#f4f6f8',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        backgroundColor: '#f4f6f8',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: 56
        }
    },
    userMenu: {
        marginTop: 'auto',
        paddingBottom: 8,
        minWidth: 56,
        paddingLeft: 9,
        paddingRight: 9
    },
    logo: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.divider}`
    },
    brandLogo: {
        minWidth: 56,
        paddingLeft: 7.5,
        paddingRight: 7.5,
        paddingTop: 8,
        marginBottom: 8
    },
    dockedLeft: {
        borderRight: 'none'
    }
}));

const Sidebar = (props) => {
    const { open, variant, onClose, handleSidebarOpen, className, user, activeUnit, units, ...rest } = props;
    const { t, i18n } = useTranslation();
    const [logo, setLogo] = React.useState('');

    const { authenticated } = useContext(AuthContext);
    const {
        state: { activePermissions }
    } = useContext(ActiveUnitContext);

    const { data: pbiReports } = usePowerBIReportGetReportsForUnit({ unitId: activeUnit?.id });

    const classes = useStyles({ open });

    const pagesWithAuthAndPermissions = pagesAuthenticated(t, i18n, activePermissions, activeUnit, pbiReports?.reports);

    const pagesAnonymous = [
        {
            title: 'Login',
            href: '/sign-in',
            icon: <LockOpenIcon />
        },
        {
            title: 'About',
            href: '/About',
            icon: <InfoIcon />
        },
        {
            title: 'Settings',
            href: '/settings',
            icon: <SettingsIcon />
        }
    ];

    useEffect(() => {
        if (activeUnit) {
            document.title = activeUnit?.name;

            let logo = null;

            if (activeUnit.logo) {
                logo = activeUnit.logo;
            } else {
                let unitId = activeUnit.parentId;

                while (unitId !== null) {
                    const unit = units.find((u) => u.id === unitId);

                    if (unit) {
                        if (unit.logo && unit.logo.length) {
                            logo = unit.logo;
                            break;
                        }

                        unitId = unit.parentId;
                    } else {
                        break;
                    }
                }
            }

            const icon = {
                sizes: '64x64 48x48 32x32 24x24 16x16',
                src: logo
            };

            switch (true) {
                //
                case /image\/svg/.test(logo):
                    icon.type = 'image/svg+xml';
                    icon.sizes = 'any';
                    icon.src = logo;
                    break;

                case /image\/jpg/.test(logo):
                case /image\/jpeg/.test(logo):
                    icon.type = 'image/jpg';
                    break;

                case /image\/png/.test(logo):
                    icon.type = 'image/png';
                    break;

                case /image\/webp/.test(logo):
                    icon.type = 'image/webp';
                    break;

                default:
                    icon.src = 'favicon.ico';
                    icon.type = 'image/x-icon';

                    break;
            }

            const manifestDetails = {
                short_name: activeUnit.name,
                name: activeUnit.name,
                icons: [icon]
            };

            reactManifest.update(manifestDetails, '#manifest-placeholder');

            setLogo(logo);
        }
    }, [activeUnit, units]);

    return (
        <Drawer
            anchor='left'
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
            })}
            classes={{
                paperAnchorDockedLeft: classes.dockedLeft,
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })
            }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <Favicon url={logo} />
            <div className={classes.brandLogo}>
                <Avatar className={classes.logo}>
                    <img src={logo} alt='logo' width='40' height='40' />
                </Avatar>
            </div>
            <div {...rest} className={clsx(classes.root, className)}>
                <SidebarNav
                    className={classes.nav}
                    pages={authenticated ? [...pagesWithAuthAndPermissions] : [...pagesAnonymous]}
                    open={open}
                    handleSidebarOpen={handleSidebarOpen}
                    onClose={onClose}
                />
                <Divider />
            </div>
            <div className={classes.userMenu}>
                <UserMenu user={props.user} isSidebarOpen={open} />
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    handleSidebarOpen: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    activeUnit: PropTypes.shape(unitModel),
    units: PropTypes.array
};

export default Sidebar;
