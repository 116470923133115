import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Avatar, Divider, IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';

import { AuthContext } from 'context/AuthContext';
import { clearAuthKeys } from 'helpers/auth';

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 32,
        height: 32
    },
    name: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold'
    },
    a: {
        color: 'black'
    }
}));

const UserMenu = ({ user, isSidebarOpen }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles({ isSidebarOpen });

    const { setUser } = useContext(AuthContext);

    const [anchorEl, setAnchorEl] = React.useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} size='small'>
                <Avatar className={classes.avatar} variant='circle'>
                    <Typography className={classes.name}>
                        {user?.firstName?.charAt(0)}
                        {user?.lastName?.charAt(0)}
                    </Typography>
                </Avatar>
            </IconButton>
            <Menu id='profile-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        history.push('/settings');
                    }}
                >
                    {t('Profile settings')}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <a
                        className={classes.a}
                        href='mailto:apps@brandcom.ai?subject=Feedback Poweranalytics&bcc=ketil@brandcom.ai'
                    >
                        {t('Give us feedback')}
                    </a>
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        clearAuthKeys();
                        setUser(null);
                        history.push('/sign-in');
                    }}
                >
                    {t('Log out')}
                </MenuItem>
            </Menu>
        </>
    );
};

UserMenu.propTypes = {
    user: PropTypes.object.isRequired,
    isSidebarOpen: PropTypes.bool.isRequired
};

export default UserMenu;
